// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import "./controllers";
import Choices from "choices.js";

Turbo.StreamActions.clear = function () {
  this.targetElements.forEach((target) => {
    const [modal = null] = target.children;

    if (modal === null) return;

    modal.remove();
  });
};

Turbo.StreamActions.update_options = function () {
  this.targetElements.forEach((target) => {
    const isChoiceJs = target.dataset.choice == "active";

    if (isChoiceJs) {
      const choices = Array.from(this.templateContent.children).map((child) => {
        return {
          value: child.getAttribute("value"),
          label: child.innerText.trim(),
        };
      });

      const choiceEvent = new CustomEvent("choices:updated", {
        detail: { target: target.id, choices },
      });

      target.dispatchEvent(choiceEvent);
      return;
    }

    Array.from(target.children).forEach((child) => child.remove());

    Array.from(this.templateContent.children).forEach((child) =>
      target.appendChild(child),
    );
  });
};
