import Dropdown from "@stimulus-components/dropdown";
import { useTransition } from "stimulus-use";

export default class extends Dropdown {
  connect() {
    useTransition(this, {
      element: this.menuTarget,
      hiddenClass: "is-hidden",
    });
  }

  hide(event) {
    if (
      !this.element.contains(event.target) &&
      !this.menuTarget.classList.contains("is-hidden")
    ) {
      this.leave();
    }
  }
}
